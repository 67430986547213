@import 'ember-paper';
@import 'paper-data-table';

div:empty {
    padding: 0;
}

.no-margin{
    margin: 0;
}

.md-tooltip{
    height: auto;
    overflow: visible !important;
    md-icon {
        color: white;
        margin-right: 3px;
    }
}

.paper-input--small {
    margin: 0;

    .md-errors-spacer {
      min-height: 0;
    }
  }

.md-list-item-indicator{
    background-color: color($accent, '500');
    padding: 3px 6px;
    border-radius: 50px;
    color: white;
    margin: 0 6px 0 3px;
}

.detail-indicator{
    margin: 8px 10px;
}

.event-place--in {
    background: #eee;
}

.filter-list{
    margin-right: 4px;
}

.list-roll-the-dice {
    margin-right: -6px;
}

.md-button.md-icon-button.right-textfield-button {
    margin-left: -38px;
}

.indicator-loader{
    background: #e91e63;
    border-radius: 100%;
    padding: 5px;
    margin: 0 6px 0 3px;
}

.indicator-loader md-progress-circular.md-accent path {
    stroke: #fff;
}

table.md-table.md-row-select td.md-cell:nth-child(n+3):nth-last-child(n+2), table.md-table.md-row-select th.md-column:nth-child(n+3):nth-last-child(n+2) {
    padding: 0 24px 0 0;
}

.button-without-dimensions.md-button.md-icon-button {
    margin: 0;
    padding: 0;
    height: auto;
    width: auto;
}

.primary-icon .paper-icon {
    color:#e91e63
}

.isNiveauNotOkay .paper-icon{
    color:#e91e63
}

.isNiveauTodo .paper-icon {
    color:#e91e63
}

.isNiveauMaybe .paper-icon {
    color: orange
}

.isNiveauOkay .paper-icon {
    color: green
}

.no-button {
    background-color: transparent;
    border: none;
    position: relative;
}

.todo {
    color:#e91e63
}

.detail-indicator md-icon {
    margin-right: 5px;
    margin-top: -2px;
}

.todo-input {
    display: block;
    margin-right: 10px;
}

.scrollable {
    overflow-y: scroll;
}

.todo-info {
    margin-right: 10px;
}

.todo-button {
    .md-button.md-icon-button{
        margin-right: -7px;
    }
    margin-right: 13px;
}

#sidenav-header{
    margin-bottom: 20px;
}

md-list-item._md-button-wrap.item-with-left-icon-button > div.md-button{
    padding-left: 0;
}

.md-button.md-icon-button.button-as-checkbox{
    margin-right: 35px
}

.button-as-checkbox md-checkbox {
    margin:2px
}

.selectcount {
    position: sticky;
    bottom: 10px;
}

.selectcount-content {
    background: #3F50B5;
    border-radius: 100px;
    color: #ffffff;
    padding: 5px 5px 5px 25px;
}

.selectcount md-icon{
    color: #ffffff
}

.active .md-button.md-no-style.md-ink-ripple {
    padding-right: 6px;
    }

md-list-item.active {
    background: paper-color($primary, '50');
    border-right: 10px #DA386F solid;
}

.teilnehmer-input {
    max-width: 600px;
    margin-left: 25px;
}

.login-error {
    background: paper-color($warn, '500');
    padding: 10px;
    color: white;
    margin-bottom: 20px
}

.selectcount .md-button.md-icon-button{
    margin: 0 3px;
}
@import 'ember-basic-dropdown';

.no-draggable{
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.list-icon{
    margin-left: 14px;
    margin-right: 14px;
}

.week-and-date-select {
  background-color: #eee;
  padding: 10px;
  margin-bottom: 18px;
}

md-input-container.week-and-date-select__week .md-input{
    width: 40px;
}

md-input-container.week-and-date-select__date .md-input{
    width: 120px;
}

.week-and-date-select label{
    color: var(--foreground-2);
    font-size: 12px;
}

.week-and-date-select md-input-container{
    margin-top: 0;
    margin-bottom: 0;
}

.activity-level{
    background-color: #6D6D6D;
    color: white;
    border-radius: 5px;
}

.optimizer-event-td{
    padding: 0 12px 0 12px !important;
}

.temp-group{
    background-color: #6D6D6D;
    color: white;
    border-radius: 300px;
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
}

.active.temp-group-, .active.temp-group-\+{
    color: paper-contrast-paper-color('grey', '500');
    background-color: paper-color('grey', '300');
}

.temp-group.temp-group-1, .temp-group.temp-group-\(1\){
    color: paper-contrast-paper-color('purple', '500');
    background-color: paper-color('purple', '500');
}
.active.temp-group-1, .active.temp-group-\(1\){
    color: paper-contrast-paper-color('purple', '500');
    background-color: paper-color('purple', '100');
}

.temp-group.temp-group-2, .temp-group.temp-group-\(2\){
    color: paper-contrast-paper-color('indigo', '500');
    background-color: paper-color('indigo', '500');
}
.active.temp-group-2, .active.temp-group-\(2\){
    color: paper-contrast-paper-color('indigo', '500');
    background-color: paper-color('indigo', '100');
}

.temp-group.temp-group-3, .temp-group.temp-group-\(3\){
    color: paper-contrast-paper-color('teal', '500');
    background-color: paper-color('teal', '500');
}
.active.temp-group-3, .active.temp-group-\(3\){
    color: paper-contrast-paper-color('teal', '500');
    background-color: paper-color('teal', '100');
}

.temp-group.temp-group-4, .temp-group.temp-group-\(4\){
    color: paper-contrast-paper-color('lime', '500');
    background-color: paper-color('lime', '500');
}
.active.temp-group-4, .active.temp-group-\(4\){
    color: paper-contrast-paper-color('lime', '500');
    background-color: paper-color('lime', '100');
}

.temp-group.temp-group-5, .temp-group.temp-group-\(5\){
    color: paper-contrast-paper-color('amber', '500');
    background-color: paper-color('amber', '500');
}
.active.temp-group-5, .active.temp-group-\(5\){
    color: paper-contrast-paper-color('amber', '500');
    background-color: paper-color('amber', '100');
}

.list-heading {
  padding: 15px 0 5px;
  h3 {
    margin: 0;
    line-height: 1;
  }
}

.warn-box{
  background-color: paper-color($warn, '100');
}

.connection-indicator {
  background-color: #e91e63;
  padding: 3px 6px;
  border-radius: 50px;
  color: white;
  margin: 0 6px 0 3px;
  line-height: 1.8;
}
